export const GRADE_LEVEL = {
  GRADE_0: "GRADE_0",
  GRADE_1: "GRADE_1",
  GRADE_2: "GRADE_2",
  GRADE_3: "GRADE_3",
  GRADE_4: "GRADE_4",
  GRADE_5: "GRADE_5",
  GRADE_6: "GRADE_6",
  GRADE_7: "GRADE_7",
  GRADE_8: "GRADE_8",
  GRADE_9: "GRADE_9",
  GRADE_10: "GRADE_10",
};

export const LIST_GRADE = Object.keys(GRADE_LEVEL);

export const STARTERS_GRADE = [
  GRADE_LEVEL.GRADE_0,
  GRADE_LEVEL.GRADE_1,
  GRADE_LEVEL.GRADE_2,
];
export const MOVERS_GRADE = [
  GRADE_LEVEL.GRADE_3,
  GRADE_LEVEL.GRADE_4,
  GRADE_LEVEL.GRADE_5,
];
export const FLYER_GRADE = [
  GRADE_LEVEL.GRADE_6,
  GRADE_LEVEL.GRADE_7,
  GRADE_LEVEL.GRADE_8,
  GRADE_LEVEL.GRADE_9,
];

export const IELTS_GRADE = [GRADE_LEVEL.GRADE_10];

export const FULL_TEACHER_GRADE_LEVEL = [STARTERS_GRADE, MOVERS_GRADE, FLYER_GRADE, IELTS_GRADE]
